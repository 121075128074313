import { Injectable } from '@angular/core';
import { AngularFirestore, CollectionReference } from '@angular/fire/firestore';
import { Observable, of } from 'rxjs';
import { AngularFireFunctions } from '@angular/fire/functions';
import firebase from 'firebase/app';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { Inventory, Category, Product, OptionGroup, Option } from '../models/menus'
import {BusinessProfile} from '../models/business-profile'
import {GeoJsonPoint} from '../models/geojson'
import {Order} from '../models/orders'


@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
  	private firestore: AngularFirestore,
  	private cloudFunctions: AngularFireFunctions,
    private cloudStorage: AngularFireStorage,
  	) { }

  public subscribeToTopic(businessID, token) {
    const callable = this.cloudFunctions.httpsCallable('subscribeToTopic')
    return callable({ businessID: businessID, token: token });
  }

  public saveNotificationToken(userDetails, token) {
      let userRef = this.getDocumentRefFromCategory('users', userDetails.uid)
      userRef.set({
        displayName: userDetails.displayName,
        email: userDetails.email,
        emailVerified: true,
        photoURL: userDetails.photoURL,
        properties: {
          notificationToken: token,
          //dateOfCreation: this.dateOfCreation
        },
        uid: userDetails.uid
      }, { merge: true }).then(() => {
        // Update successful.
         console.log("Fields added successfully to new user profile")
      }).catch((error) => {
        // An error happened.
        console.log("error adding fields to new user profile: ", error)
      })
  }

  public async upgradeUserAndCreateNewBusiness(userId) {
    let batch = this.firestore.firestore.batch()

    // create inventory
    let newInventoryRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('INVENTORIES').doc()
    let inventory = new Inventory('')
    batch.set(newInventoryRef, JSON.parse(JSON.stringify(inventory)))

    // create new business as GeoJsonPoint
    const businessRef = this.firestore.firestore.collection('BUSINESSES').doc()
    const businessProperties = {
        name:'',
        inventories: firebase.firestore.FieldValue.arrayUnion(newInventoryRef.id),
        open:true,
        stripeAccountId: '',
        stripeChargesEnabled: false,
        stripeDetailsSubmitted: false,
        onboardingComplete: false,
        businessProfile: JSON.parse(JSON.stringify(new BusinessProfile())),
      }
    const querySnapshot = await this.firestore.firestore.collection('BUSINESSES')
                          .orderBy('code', 'desc')
                          .limit(1)
                          .get()
    let code = ''
    if (querySnapshot.docs.length > 0) {
       code = (parseFloat(querySnapshot.docs[0].data().code)+1).toFixed()
    } else {
       code = '1000'
    }
    
    let businessObj:GeoJsonPoint = new GeoJsonPoint(['17.63652','59.87408'], code, businessProperties)
    batch.set(businessRef, {...businessObj})

    // upgrade the user with properties
    let userRef = this.firestore.firestore.collection('users').doc(userId)
    batch.set(userRef, {
      properties: {
            role: 'buisiness-owner',
            businessIds: firebase.firestore.FieldValue.arrayUnion(businessRef.id),
        },
    }, {merge:true})

    return batch.commit()
  }

  public getUser(userId:string) {
    return this.firestore.collection('users').doc(userId).valueChanges()
  }

  public getBusiness(businessId:string) {
    return this.firestore.collection<GeoJsonPoint>('BUSINESSES').doc(businessId).valueChanges({idField:'docId'})
  }

  public getBusinesses(businessIds:string[]) {
    return this.firestore.collection<GeoJsonPoint>('BUSINESSES',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', businessIds)).valueChanges({idField:'docId'})
  }

  public getInventories(businessesIds:string[]) {
    return this.firestore.collection<Inventory>('INVENTORIES',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', businessesIds)).valueChanges({idField:'docId'})
  }

  public getCategories(categoriesIds:string[]) {
    return this.firestore.collection<Inventory>('CATEGORIES',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', categoriesIds)).valueChanges({idField:'docId'})
  }

  public getProducts(productsIds:string[]) {
    return this.firestore.collection<Inventory>('PRODUCTS',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', productsIds)).valueChanges({idField:'docId'})
  }

  public getOptionGroups(optionGroupsIds:string[]) {
    return this.firestore.collection<Inventory>('OPTION_GROUPS',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', optionGroupsIds)).valueChanges({idField:'docId'})
  }

  public getOptions(optionIds:string[]) {
    return this.firestore.collection<Inventory>('OPTIONS',
      ref => ref.where(firebase.firestore.FieldPath.documentId(), 'in', optionIds)).valueChanges({idField:'docId'})
  }

  /*public async addNewInventory(inventory:Inventory, businessId:string) {
    let promises:AngularFireUploadTask[] = [] 

    try {
      await this.saveNewPhotos(inventory)
    } catch (error) {
      console.log('addNewInventory error: saveNewPhotos failed')
      return Promise.reject()
    }
    let batch = this.firestore.firestore.batch()
    // save inventory
    let newInventoryRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('MENUS').doc()
    let newInventoryID = newInventoryRef.id
    let newInventoryDeepCopy = JSON.parse(JSON.stringify(inventory))
    newInventoryDeepCopy.categories = []
    batch.set(newInventoryRef, newInventoryDeepCopy);
    // save categories
    inventory.categories.forEach((category:Category) => {
      let newCategoryRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('CATEGORIES').doc()
      let newCategoryDeepCopy = JSON.parse(JSON.stringify(category))
      newCategoryDeepCopy.productss = []
      batch.set(newCategoryRef, newCategoryDeepCopy)
      batch.set(newInventoryRef, {
        categories: firebase.firestore.FieldValue.arrayUnion(newCategoryRef.id)
      }, { merge: true })
      // save productss
      category.productss.forEach((products:Meal) => {
        let newMealRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('MEALS').doc()
        let newMealDeepCopy = JSON.parse(JSON.stringify(products))
        newMealDeepCopy.optionGroups = []
        batch.set(newMealRef, newInventoryDeepCopy)
        batch.set(newCategoryRef, {
          productss: firebase.firestore.FieldValue.arrayUnion(newMealRef.id)
        }, { merge: true })
        // save option Groups
        products.optionGroups.forEach((optionGroup:OptionGroup) => {
          let newOptionGroupRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('OPTION_GROUPS').doc()
          let newOptionGroupDeepCopy = JSON.parse(JSON.stringify(optionGroup))
          newOptionGroupDeepCopy.options = []
          batch.set(newOptionGroupRef, newOptionGroupDeepCopy)
          batch.set(newMealRef, {
            optionGroups: firebase.firestore.FieldValue.arrayUnion(newOptionGroupRef.id)
          }, { merge: true })
          // save options
          optionGroup.options.forEach((option:Option) => {
            let newOptionRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('OPTIONS').doc()
            batch.set(newOptionRef, JSON.parse(JSON.stringify(option)))
            batch.set(newOptionGroupRef, {
              options: firebase.firestore.FieldValue.arrayUnion(newOptionRef.id)
            }, { merge: true })
          })
        })
      })
    })
    // save inventory in business
    let businessRef:firebase.firestore.DocumentReference = this.getDocumentRefFromCategory('RESTAURANTS', businessId)
    batch.set(businessRef, {
      properties: {
        inventories: firebase.firestore.FieldValue.arrayUnion(newInventoryID)
      }
    }, { merge: true })
    
    return batch.commit()
      
  }*/

  public async addNewInventory(inventory:Inventory, businessId:string) {
    let promises:AngularFireUploadTask[] = [] 

    try {
      await this.saveNewPhotos(inventory)
    } catch (error) {
      console.log('addNewInventory error: saveNewPhotos failed')
      return Promise.reject()
    }

    let newInventoryRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('INVENTORIES').doc()
    let newInventoryID = newInventoryRef.id
    let batch = this.firestore.firestore.batch()
    batch.set(newInventoryRef, JSON.parse(JSON.stringify(inventory)));
    let businessRef:firebase.firestore.DocumentReference = this.getDocumentRefFromCategory('BUSINESSES', businessId)
    batch.set(businessRef, {
      properties: {
        inventories: firebase.firestore.FieldValue.arrayUnion(newInventoryID)
      }
    }, { merge: true })
    
    return batch.commit()
      
  }


  public async updateInventory(inventory:Inventory) {
    const docId = inventory.docId
    try {
      await this.updatePhotos(inventory)
      console.log('updatePhotos successful')
      //delete inventory.docId
      return this.firestore.collection('INVENTORIES').doc(docId).set(JSON.parse(JSON.stringify(inventory)))
    } catch (error) {
      console.log('updatePhotos error', error)
      return Promise.reject()
    }
  }

  public async deleteInventory(inventory:Inventory, businessID:string) {
    //this.deletePhotos(inventory)
    let batch = this.firestore.firestore.batch()
    try {
      await this.deletePhotos(inventory)
    } catch(error) {
      console.log('deletePhotos failed')
      return Promise.reject(error)
    }
    let inventoryRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('INVENTORIES').doc(inventory.docId)
    batch.delete(inventoryRef)
    let businessRef:firebase.firestore.DocumentReference = this.firestore.firestore.collection('BUSINESSES').doc(businessID)
    batch.update(businessRef, {
      'properties.inventories' : firebase.firestore.FieldValue.arrayRemove(inventory.docId)
    })

    return batch.commit()
  }

  public async saveNewPhotos(inventory:Inventory) {
    let photosFailedToUpload = []
    for (let category of inventory.categories) {
      if(category.localPhotoUrl != '') {
        try {
          let response = this.addPhotoToCloudStorage(category.photoFile)
          await response.promise
          let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
          category.photoRef = response.path
          category.photoUrl = downloadUrl
          category.localPhotoUrl = ''
          delete category.photoFile
        } catch(error) {
          console.log('saveNewPhotos error: '+category.photoFile+' failed to upload', error)
          photosFailedToUpload.push(category.photoFile)
          return Promise.reject()
        }
      }
      for (let products of category.products) {
        if(products.localPhotoUrl != '') {
          try {
            let response = this.addPhotoToCloudStorage(products.photoFile)
            await response.promise
            let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
            products.photoRef = response.path
            products.photoUrl = downloadUrl
            products.localPhotoUrl = ''
            delete products.photoFile
          }catch(error) {
            console.log('saveNewPhotos error: '+products.photoFile+' failed to upload', error)
            photosFailedToUpload.push(products.photoFile)
            return Promise.reject()
          }
        }
        for (let optionGroup of products.optionGroups) {
          for (let option of optionGroup.options) {
            if(option.localPhotoUrl != '') {
              try {
                let response = this.addPhotoToCloudStorage(option.photoFile)
                await response.promise
                let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                option.photoRef = response.path
                option.photoUrl = downloadUrl
                option.localPhotoUrl = ''
                delete option.photoFile
              } catch (error) {
                console.log('saveNewPhotos error: '+option.photoFile+' failed to upload', error)
                photosFailedToUpload.push(option.photoFile)
                return Promise.reject()
              }
            }
          }
        }
      }

      for(let subCategory of category.subCategories) {
        if(subCategory.localPhotoUrl != '') {
          try {
            let response = this.addPhotoToCloudStorage(subCategory.photoFile)
            await response.promise
            let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
            subCategory.photoRef = response.path
            subCategory.photoUrl = downloadUrl
            subCategory.localPhotoUrl = ''
            delete subCategory.photoFile
          } catch(error) {
            console.log('saveNewPhotos error: '+subCategory.photoFile+' failed to upload', error)
            photosFailedToUpload.push(subCategory.photoFile)
            return Promise.reject()
          }
        }
        for (let products of subCategory.products) {
          if(products.localPhotoUrl != '') {
            try {
              let response = this.addPhotoToCloudStorage(products.photoFile)
              await response.promise
              let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
              products.photoRef = response.path
              products.photoUrl = downloadUrl
              products.localPhotoUrl = ''
              delete products.photoFile
            }catch(error) {
              console.log('saveNewPhotos error: '+products.photoFile+' failed to upload', error)
              photosFailedToUpload.push(products.photoFile)
              return Promise.reject()
            }
          }
          for (let optionGroup of products.optionGroups) {
            for (let option of optionGroup.options) {
              if(option.localPhotoUrl != '') {
                try {
                  let response = this.addPhotoToCloudStorage(option.photoFile)
                  await response.promise
                  let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                  option.photoRef = response.path
                  option.photoUrl = downloadUrl
                  option.localPhotoUrl = ''
                  delete option.photoFile
                } catch (error) {
                  console.log('saveNewPhotos error: '+option.photoFile+' failed to upload', error)
                  photosFailedToUpload.push(option.photoFile)
                  return Promise.reject()
                }
              }
            }
          }
        }
      }
    }
    return Promise.resolve(inventory)
  }

  public async updatePhotos(inventory:Inventory) {
    let photosFailedToUpload = []
    let photosFailedToDelete = []
    for(let category of inventory.categories) {
        if (category.localPhotoUrl != '')    {
          if (category.photoUrl != '') {
            try {
              await this.deletePhotoFromCloudStorage(category.photoRef)
              category.photoRef = ''
              category.photoUrl = ''
            } catch (error) {
              console.log('updatePhotos error: '+category.photoUrl+' failed to delete', error)
              photosFailedToDelete.push(category.photoUrl)
              return Promise.reject()
            }
          }
          
          try {
            let response = await this.addPhotoToCloudStorage(category.photoFile)
            await response.promise
            let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
            category.photoRef = response.path
            category.photoUrl = downloadUrl
            category.localPhotoUrl = ''
            delete category.photoFile
          } catch (error) {
            console.log('updatePhotos error: '+category.photoFile+' failed to upload', error)
            photosFailedToUpload.push(category.photoFile)
            return Promise.reject()
          }
          
      }
      for (let products of category.products) {
        if(products.localPhotoUrl != '') {
          if(products.photoUrl != '') {
            try {
              await this.deletePhotoFromCloudStorage(products.photoRef)
              products.photoRef = ''
              products.photoUrl = ''
            } catch (error) {
              console.log('updatePhotos error: '+products.photoUrl+' failed to delete', error)
              photosFailedToDelete.push(products.photoUrl)
              return Promise.reject()
            }
          } 
          
          try {
            let response = this.addPhotoToCloudStorage(products.photoFile)
            await response.promise
            let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
            products.photoRef = response.path
            products.photoUrl = downloadUrl
            products.localPhotoUrl = ''
            delete products.photoFile
          } catch (error) {
            console.log('updatePhotos error: '+products.photoFile+' failed to upload', error)
            photosFailedToUpload.push(products.photoFile)
            return Promise.reject()
          }
          
        }
        for(let optionGroup of products.optionGroups) {
          for (let option of optionGroup.options) {
            if(option.localPhotoUrl != '') {
              if (option.photoUrl != '') {
                try {
                  await this.deletePhotoFromCloudStorage(option.photoRef)
                  option.photoRef = ''
                  option.photoUrl = ''
                } catch (error) {
                  console.log('updatePhotos error: '+option.photoUrl+' failed to delete', error)
                  photosFailedToDelete.push(option.photoUrl)
                  return Promise.reject()
                }
              }
              
              try {
                let response = this.addPhotoToCloudStorage(option.photoFile)
                await response.promise
                let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                option.photoRef = response.path
                option.photoUrl = downloadUrl
                option.localPhotoUrl = ''
                delete option.photoFile
              } catch (error) {
                console.log('updatePhotos error: '+option.photoFile+' failed to upload', error)
                photosFailedToUpload.push(option.photoFile)
                return Promise.reject()
              }
              
            } 
          }
        }
      }

      for(let subCategory of category.subCategories) {
          if (subCategory.localPhotoUrl != '') {
              if (subCategory.photoUrl != '') {
                try {
                  await this.deletePhotoFromCloudStorage(category.photoRef)
                  subCategory.photoRef = ''
                  subCategory.photoUrl = ''
                } catch (error) {
                  console.log('updatePhotos error: '+subCategory.photoUrl+' failed to delete', error)
                  photosFailedToDelete.push(subCategory.photoUrl)
                  return Promise.reject()
                }
              }
              
              try {
                let response = await this.addPhotoToCloudStorage(subCategory.photoFile)
                await response.promise
                let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                subCategory.photoRef = response.path
                subCategory.photoUrl = downloadUrl
                subCategory.localPhotoUrl = ''
                delete subCategory.photoFile
              } catch (error) {
                console.log('updatePhotos error: '+subCategory.photoFile+' failed to upload', error)
                photosFailedToUpload.push(subCategory.photoFile)
                return Promise.reject()
              }
              
          }

          for (let products of subCategory.products) {
            if(products.localPhotoUrl != '') {
              if(products.photoUrl != '') {
                try {
                  await this.deletePhotoFromCloudStorage(products.photoRef)
                  products.photoRef = ''
                  products.photoUrl = ''
                } catch (error) {
                  console.log('updatePhotos error: '+products.photoUrl+' failed to delete', error)
                  photosFailedToDelete.push(products.photoUrl)
                  return Promise.reject()
                }
              } 
              
              try {
                let response = this.addPhotoToCloudStorage(products.photoFile)
                await response.promise
                let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                products.photoRef = response.path
                products.photoUrl = downloadUrl
                products.localPhotoUrl = ''
                delete products.photoFile
              } catch (error) {
                console.log('updatePhotos error: '+products.photoFile+' failed to upload', error)
                photosFailedToUpload.push(products.photoFile)
                return Promise.reject()
              }
              
            }
            for(let optionGroup of products.optionGroups) {
              for (let option of optionGroup.options) {
                if(option.localPhotoUrl != '') {
                  if (option.photoUrl != '') {
                    try {
                      await this.deletePhotoFromCloudStorage(option.photoRef)
                      option.photoRef = ''
                      option.photoUrl = ''
                    } catch (error) {
                      console.log('updatePhotos error: '+option.photoUrl+' failed to delete', error)
                      photosFailedToDelete.push(option.photoUrl)
                      return Promise.reject()
                    }
                  }
                  
                  try {
                    let response = this.addPhotoToCloudStorage(option.photoFile)
                    await response.promise
                    let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
                    option.photoRef = response.path
                    option.photoUrl = downloadUrl
                    option.localPhotoUrl = ''
                    delete option.photoFile
                  } catch (error) {
                    console.log('updatePhotos error: '+option.photoFile+' failed to upload', error)
                    photosFailedToUpload.push(option.photoFile)
                    return Promise.reject()
                  }
                  
                } 
              }
            }
          }
      }
    }

    return Promise.resolve(inventory)
  }

  public async deletePhotos(inventory:Inventory) {
    let photosFailedToDelete = []
    for (let category of inventory.categories) {
      if(category.photoUrl != '') {
        try {
          let response = await this.deletePhotoFromCloudStorage(category.photoRef).toPromise()
        } catch(error) {
          console.log('deletePhotos error: '+category.photoUrl+' failed to be deleted', error)
          photosFailedToDelete.push(category.photoUrl)
          return Promise.reject()
        }
      }
      for (let products of category.products) {
        if(products.photoUrl != '') {
          try {
            let response = await this.deletePhotoFromCloudStorage(products.photoRef).toPromise()
          }catch(error) {
            console.log('deletePhotos error: '+products.photoUrl+' failed to be deleted', error)
            photosFailedToDelete.push(products.photoUrl)
            return Promise.reject()
          }
        }
        for (let optionGroup of products.optionGroups) {
          for (let option of optionGroup.options) {
            if(option.photoUrl != '') {
              try {
                let response = await this.deletePhotoFromCloudStorage(option.photoRef).toPromise()
              } catch (error) {
                console.log('deletePhotos error: '+option.photoUrl+' failed to be deleted', error)
                photosFailedToDelete.push(option.photoUrl)
                return Promise.reject()
              }
            }
          }
        }
      }

      for (let subCategory of category.subCategories) {
        if(subCategory.photoUrl != '') {
          try {
            let response = await this.deletePhotoFromCloudStorage(subCategory.photoRef).toPromise()
          } catch(error) {
            console.log('deletePhotos error: '+subCategory.photoUrl+' failed to be deleted', error)
            photosFailedToDelete.push(subCategory.photoUrl)
            return Promise.reject()
          }
        }
        for (let products of subCategory.products) {
          if(products.photoUrl != '') {
            try {
              let response = await this.deletePhotoFromCloudStorage(products.photoRef).toPromise()
            }catch(error) {
              console.log('deletePhotos error: '+products.photoUrl+' failed to be deleted', error)
              photosFailedToDelete.push(products.photoUrl)
              return Promise.reject()
            }
          }
          for (let optionGroup of products.optionGroups) {
            for (let option of optionGroup.options) {
              if(option.photoUrl != '') {
                try {
                  let response = await this.deletePhotoFromCloudStorage(option.photoRef).toPromise()
                } catch (error) {
                  console.log('deletePhotos error: '+option.photoUrl+' failed to be deleted', error)
                  photosFailedToDelete.push(option.photoUrl)
                  return Promise.reject()
                }
              }
            }
          }
        }
      }
    }
    if (photosFailedToDelete.length > 1) {
      Promise.reject(photosFailedToDelete)
    } else {
      return Promise.resolve()
    }
  }

  public addPhotoToCloudStorage(photo: File|Blob) {
    // Create file metadata including the content type
    var metadata = {
      //contentType: 'image/jpeg',
    }

    // create a random id
    const randomId = Math.random().toString(36).substring(2)

    // create a reference to the storage bucket location
    var ref = this.cloudStorage.ref('/images/' + randomId)

    // the put method creates an AngularFireUploadTask
    // and kicks off the upload
    var task = ref.put(photo)

    var result = {path: '/images/'+randomId, promise:task}

    return result;
  }

  public deletePhotoFromCloudStorage(photoRef:string) {
    return this.cloudStorage.ref(photoRef).delete();
  }

  public getPhotoUrlFromCloudStorage(photoPath:string) {
    var photoUrl: Observable<string | null>;
    var photoRef = this.cloudStorage.ref(photoPath)
    photoUrl = photoRef.getDownloadURL()

    return photoUrl
  }

  // public getNewOrders(): Observable<any[]> {
  //   return this.firestore.collection('NEW_ORDER').valueChanges()
  // }

  private getDocumentRefFromCategory(category, documentID): firebase.firestore.DocumentReference {
    return this.firestore.firestore.collection(category).doc(documentID)
  }

  //   public subscribeToTopic(appId, token) {
  //   const callable = this.cloudFunctions.httpsCallable('subscribeToTopic')
  //   return callable({ appId: appId, token: token });
  // }

 

  public stripeCreateAccount(userId) {
    const callable = this.cloudFunctions.httpsCallable('stripeCreateAccount')
    return callable({userId:userId});
  }

  public stripeGetAccountLink(accountId) {
    const callable = this.cloudFunctions.httpsCallable('stripeGetAccountLink')
    return callable({accountId:accountId});
  }

  public stripeGetExpressDashboardLink(accountId) {
    const callable = this.cloudFunctions.httpsCallable('stripeGetExpressDashboardLink')
    return callable({accountId:accountId});
  }

  public getOrders(businessID:string) {
    return this.firestore.collection<Order>('ORDERS', 
      ref => ref.where('businessID', '==', businessID)
                .where('status', 'in' , 
                  ['PAYED', 'UNFULFILLED', 'FULFILLED', 'DELIVERED', 'CANCELLED', 'SUBMITTED'])
                .orderBy('createdAt', "desc")
                )
    .valueChanges({idField:'docID'})
  }

  public async fulfillOrder(orderID:string) {
  	let orderRef = this.firestore.collection<Order>('ORDERS').doc(orderID)
    return orderRef.update({status:'FULFILLED'})
  }

  public unfulfillOrder(orderID:string) {
    let orderRef = this.firestore.collection<Order>('ORDERS').doc(orderID)
    return orderRef.update({status:'UNFULFILLED'})
  }

  public closeOrder(orderID:string) {
  	let orderRef = this.firestore.collection<Order>('ORDERS').doc(orderID)
    return orderRef.update({status:'DELIVERED'})
  }

  public uncloseOrder(orderID:string) {
    let orderRef = this.firestore.collection<Order>('ORDERS').doc(orderID)
    return orderRef.update({status:'FULFILLED'})
  }

  public cancelOrder(orderID:string) {
  	let orderRef = this.firestore.collection<Order>('ORDERS').doc(orderID)
    return orderRef.update({status:'CANCELLED'})
  }

  public async updateProfile(businessID:string, businessProfile:BusinessProfile, userID:string,
    claim:any) {
    try {
      await this.updateLogo(businessProfile)
      await this.firestore.collection('BUSINESSES').doc(businessID).set({
        geometry: {
          coordinates: [businessProfile.address.coordinates.longitude,
          businessProfile.address.coordinates.latitude]
        },
        properties: {
          businessProfile: JSON.parse(JSON.stringify(businessProfile)),
        },
      }, {merge:true})
      const callable = this.cloudFunctions.httpsCallable('updateCustomClaims')
      return callable({ userID: userID, claim: claim }).toPromise();

    } catch (error) {
      console.log('updateProfile error: saveNewLogo failed')
      return Promise.reject()
    }
    
  }

  public async updateWaitingTimes(businessID:string, businessProfile:BusinessProfile) {
    try {
      await this.firestore.collection('BUSINESSES').doc(businessID).set({
        properties: {
          businessProfile: {
            waitingTimeRestaurant: businessProfile.waitingTimeRestaurant,
            waitingTimeDelivery: businessProfile.waitingTimeDelivery
          }
        }
      },{merge:true})
    } catch (error) {
      console.log('updateWaitingTimes error', error)
      return Promise.reject()
    }
  }

  async saveNewLogo(businessProfile:BusinessProfile) {
    if(businessProfile.localPhotoUrl != '') {
      try {
        let response = this.addPhotoToCloudStorage(businessProfile.photoFile)
        await response.promise
        let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
        businessProfile.photoRef = response.path
        businessProfile.photoUrl = downloadUrl
        businessProfile.localPhotoUrl = ''
        delete businessProfile.photoFile
      } catch(error) {
        console.log('saveNewLogo error: '+businessProfile.photoFile+' failed to upload', error)
        return Promise.reject()
      }
    }
  }

  async updateLogo(businessProfile:BusinessProfile) {
    if (businessProfile.localPhotoUrl != '')    {
        if (businessProfile.photoUrl != '') {
          try {
            await this.deletePhotoFromCloudStorage(businessProfile.photoRef)
            businessProfile.photoRef = ''
            businessProfile.photoUrl = ''
          } catch (error) {
            console.log('updatePhotos error: '+businessProfile.photoUrl+' failed to delete', error)
            return Promise.reject()
          }
        }
        
        try {
          let response = await this.addPhotoToCloudStorage(businessProfile.photoFile)
          await response.promise
          let downloadUrl = await this.cloudStorage.ref(response.path).getDownloadURL().toPromise()
          businessProfile.photoRef = response.path
          businessProfile.photoUrl = downloadUrl
          businessProfile.localPhotoUrl = ''
          delete businessProfile.photoFile
        } catch (error) {
          console.log('updatePhotos error: '+businessProfile.photoFile+' failed to upload', error)
          return Promise.reject()
        }
        
    }
  }

  
}
