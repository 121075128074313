export class BusinessProfile {
	
	type:'Selling' | 'Booking'
	name:string
	email:string
	phone:string
	address:Address
	openingHours:OpeningHour[]
	swishNumber:string
	photoUrl:string
	photoRef:string
	photoFile?:Blob
	localPhotoUrl:string
	currency:string
	waitingTimeRestaurant:number
	waitingTimeDelivery:number

	constructor() {
		this.type = 'Selling'
		this.name = ''
		this.email = ''
		this.phone = ''
		this.address = new Address()
		this.openingHours = [new OpeningHour()]
		this.swishNumber = ''
		this.photoUrl = ''
		this.photoRef = ''
		this.localPhotoUrl = ''
		this.currency = 'sw'
		this.waitingTimeRestaurant = 15
		this.waitingTimeDelivery = 30
	}
}

export class Address {
	address:string
	postalCode:string
	city:string
	coordinates:{latitude:string, longitude:string}

	constructor() {
		this.address = ''
		this.postalCode = ''
		this.city = ''
		this.coordinates = {latitude:'', longitude:''}
	}
}

export class OpeningHour {
	days:['Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Sunday'] | []
	opening:string
	closing:string

	constructor() {
		this.days = []
		this.opening = ''
		this.closing = ''
	}
}