import { Injectable } from '@angular/core';
import {Subject, Observable, BehaviorSubject} from "rxjs";
import {take} from 'rxjs/operators'
import { TowerControlMessage } from '../models/tower-control-message'
import {GeoJsonPoint} from '../models/geojson'
import {User} from '../models/authentication'
import {FirebaseService} from './firebase.service'

@Injectable({
  providedIn: 'root'
})
export class TowerControlService {

	// public UserSource: Subject<User>;
	// public BusinessesSource: Subject<GeoJsonPoint[]>;
	// public CurrentBusinessSource: Subject<GeoJsonPoint>

	// public User$: Observable<User>;
	// public Businesses$: Observable<GeoJsonPoint[]>;
	// public CurrentBusiness$: Observable<GeoJsonPoint>

	public User$:BehaviorSubject<User> = new BehaviorSubject<User>(null);
	public Businesses$:BehaviorSubject<GeoJsonPoint[]> = new BehaviorSubject<GeoJsonPoint[]>(null);
	public CurrentBusiness$:BehaviorSubject<GeoJsonPoint> = new BehaviorSubject<GeoJsonPoint>(null)

  constructor(private firebaseService:FirebaseService) {
 //  	this.UserSource = new Subject<User>()
	// this.BusinessesSource = new Subject<GeoJsonPoint[]>()
	// this.CurrentBusinessSource = new Subject<GeoJsonPoint>()

	// this.User$ = this.UserSource.asObservable()
	// this.Businesses$ = this.BusinessesSource.asObservable()
	// this.CurrentBusiness$ = this.CurrentBusinessSource.asObservable()

	this.User$.subscribe((user:User) => {
		if (user) {
			this.firebaseService.getBusinesses(user.properties.businessIds)
			.subscribe((businesses:GeoJsonPoint[]) => {
				// this.BusinessesSource.next(businesses)
				// this.CurrentBusinessSource.next(businesses[0])
				this.Businesses$.next(businesses)
				this.CurrentBusiness$.next(businesses[0])
				console.log('TowerControlService got businesses', businesses)
				console.log('TowerControlService got current business', businesses[0])
			})
		}
	})
   }

   public changeBusiness(business:GeoJsonPoint) {
   	//this.CurrentBusinessSource.next(business)
   	this.CurrentBusiness$.next(business)
   }
}

