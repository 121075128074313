import { Injectable, NgZone } from '@angular/core';
//import { auth } from '@firebase/app';
import { User } from '../../models/authentication'
import { Router } from "@angular/router";
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { UtilsService } from '../../utils.service'
import { Observable } from 'rxjs'
import { NbMenuService } from '@nebular/theme';
import firebase from 'firebase/app'
import {FirebaseService} from '../../services/firebase.service'
import {TowerControlService} from '../../services/tower-control.service'


@Injectable({
  providedIn: 'root'
})
export class AuthServiceService {
	public user$: Observable<any>;
	public user:firebase.User
	public onboardingComplete:boolean = false

	constructor(
		public afStore: AngularFirestore,
		public ngFireAuth: AngularFireAuth,
		public router: Router,  
		public ngZone: NgZone,
		public utilsService: UtilsService,
		private menuService: NbMenuService,
		private firebaseService: FirebaseService,
		private towerCtrlService: TowerControlService,
	 ) { 
		this.user$ = this.ngFireAuth.authState
		this.user$.subscribe((user) => {
			if(user) {
				this.user = user
				this.firebaseService.getUser(user.uid).subscribe(async (userData:any) => {
					if(!userData.properties.businessIds) {
						await this.firebaseService.upgradeUserAndCreateNewBusiness(userData.uid).then(()=>{
							console.log('user upgraded with properties, new business and new inventory')
						})
					} else {
						if(userData.properties.businessIds.length === 0) {
							await this.firebaseService.upgradeUserAndCreateNewBusiness(userData.uid).then(()=>{
							console.log('user upgraded with properties, new business and new inventory')
						})
					}
					}
					
					//this.towerCtrlService.UserSource.next(userData)
					this.towerCtrlService.User$.next(userData)
					const token = await user.getIdTokenResult()
					console.log(token.claims)
					if (token.claims.profileComplete===true) { // check claims
						this.onboardingComplete = true
                        //this.router.navigate(['/pages/inventories'])
                    }
				})
			}
		})
		// Logout
		this.menuService.onItemClick()
	    //.pipe(takeUntil(this.destroy$))
	    .subscribe((event) => {
	      if (event.item.title === 'Log out') {
	        console.log('logout clicked');
	        //this.router.navigate(['/auth/logout'])
	        this.logout()
	      }
	    });
	}

	logout() {
		this.router.navigate(['/auth/logout'])
	}

		// Login in with email/password
	SignIn(email, password) {
		return this.ngFireAuth.signInWithEmailAndPassword(email, password).then(()=> {
			this.utilsService.showSuccessfulToast('Authentification Réussie')
		      this.router.navigate(['pages']);
		})
	}

	// Register user with email/password
	RegisterUser(email, password) {
		return this.ngFireAuth.createUserWithEmailAndPassword(email, password)
	}

	// Email verification when new user register
	SendVerificationMail() {
		return this.ngFireAuth.currentUser.then(user => user.sendEmailVerification())
		.then(() => {
		   //this.router.navigate(['verify-email']);
		})
	}

	// Recover password
	PasswordRecover(passwordResetEmail) {
		return this.ngFireAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		  //window.alert('Password reset email has been sent, please check your inbox.');
		}).catch((error) => {
		  window.alert(error)
		})
	}

	// Returns true when user is looged in
	get isLoggedIn(): boolean {
		const user = JSON.parse(localStorage.getItem('user'));
		return (user !== null && user.emailVerified !== false) ? true : false;
	}

	// Returns true when user is looged in
	public isAuthenticated(): boolean {
		const user = JSON.parse(localStorage.getItem('user'));
		return (user !== null /*&& user.emailVerified !== false*/) ? true : false;
	}

	// Returns true when user's email is verified
	get isEmailVerified(): boolean {
		const user = JSON.parse(localStorage.getItem('user'));
		return (user.emailVerified !== false) ? true : false;
	}

	get currentUser():any {
		const user = JSON.parse(localStorage.getItem('user'));
		return user
	}

	// Sign in with Gmail
	/*GoogleAuth() {
		return this.AuthLogin(new auth.GoogleAuthProvider());
	}*/

	// Auth providers
	AuthLogin(provider) {
		return this.ngFireAuth.signInWithPopup(provider)
		.then((result) => {
		   this.ngZone.run(() => {
		      this.router.navigate(['dashboard']);
		    })
		  this.SetUserData(result.user);
		}).catch((error) => {
		  window.alert(error)
		})
	}

	// Store user in localStorage
	SetUserData(user) {
		// const userRef: AngularFirestoreDocument<any> = this.afStore.doc(`users/${user.uid}`);
		// const userData: User = {
		//   uid: user.uid,
		//   email: user.email,
		//   displayName: user.displayName,
		//   photoURL: user.photoURL,
		//   emailVerified: user.emailVerified,
		//   properties: {}
		// }
		// return userRef.set(userData, {
		//   merge: true
		// })
	}

	// Sign-out 
	async SignOut() {
		//let loading = await this.presentLoading('Logging Out') 
		return this.ngFireAuth.signOut().then(() => {
		  console.log("user signed out")
		  localStorage.removeItem('user');
		  this.router.navigate(['auth']);
		  //loading.dismiss()
		  //this.presentToast()
		  this.utilsService.showSuccessfulToast('Dé-Authentification Réussie')
		})
	}
}
