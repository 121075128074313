export class Inventory {
	docId?:string
	name:string
	categories:Category[]
	visible:boolean 

	constructor(name) {
		this.name = name
		this.visible = true
		this.categories = [new Category('')]
	  	//this.categories[0].meals = [new Meal('')]
	  	//this.categories[0].meals[0].options = [new Option('')]
	}
}

export class Category {
	name:string
	subCategories:Category[]
	products:Product[]
	visible:boolean
	photoUrl:string
	photoRef:string
	photoFile?:Blob
	localPhotoUrl:string

	constructor(name) {
		this.name = name
		this.visible = true
		this.subCategories = []
		this.products = [new Product('')]
		//this.meals[0].options = [new Option('')]
		this.photoUrl = ''
		this.photoRef = ''
		this.localPhotoUrl = ''
	}
}

export class Product {
	name:string
	description:string
	price: string
	optionGroups:OptionGroup[]
	visible:boolean 
	photoUrl:string
	photoRef:string
	photoFile?:Blob
	localPhotoUrl:string

	constructor(name) {
		this.name = name
		this.visible = true
		this.description = ''
		this.price = '0.00'
		this.optionGroups = [new OptionGroup('')]
		this.photoUrl = ''
		this.photoRef = ''
		this.localPhotoUrl = ''
	}
}

export class OptionGroup {
	name:string
	options:Option[]
	visible:boolean
	multiSelection:boolean

	constructor(name) {
		this.name = name
		this.visible = true
		this.multiSelection = false
		this.options = [new Option('')]
	}
}

export class Option {
	name:string
	value: string
	price: string
	visible:boolean
	photoUrl:string
	photoRef:string
	photoFile?:Blob
	localPhotoUrl:string

	constructor(name) {
		this.name = name
		this.visible = true
		this.value = ''
		this.price= '0.00'
		this.photoUrl = ''
		this.photoRef = ''
		this.localPhotoUrl = ''
	}
}
