import { Injectable } from '@angular/core';
import { NbToastrService, NbComponentStatus } from '@nebular/theme';


@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(private toastrService: NbToastrService) { }

  showSuccessfulToast(message:string) {
    this.toastrService.success(message, 'Parfait!');
  }
  showFailureToast() {
    this.toastrService.warning('Il y a eu un souci, SVP re-essayez.', 'Re-essayez SVP !');
  }
  showNotificationToast() {
  	this.toastrService.info('Une nouvelle commande est arrivée.', 'Nouvelle Commande!')
  }

}
