import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from '@nebular/auth';

//import { LoginComponent } from './authentication/authentication.module';
//import { AuthGuardService as AuthGuard } from './services/authentication/auth-guard.service';
//import { AuthGuard } from './auth/auth-guard.service';

// import { LoginComponent } from './auth/login/login.component';
// import { RegisterComponent } from './auth/register/register.component';
// import { RequestPasswordComponent } from './auth/request-password/request-password.component';
// import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';

import { AuthGuardService } from './services/authentication/auth-guard.service'


export const routes: Routes = [
  {
    path: 'pages',
    //canActivate: [AuthGuardService],
    loadChildren: () => import('./pages/pages.module')
      .then(m => m.PagesModule),
  },
  {
    path: 'onboard',
    //canActivate: [AuthGuardService],
    loadChildren: () => import('./onboarding/onboarding.module')
      .then(m => m.OnboardingModule),
  },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./authentication/authentication.module')
  //     .then(m => m.AuthenticationModule),
  // },
  {
    path: 'auth',
    component: NbAuthComponent,
    children: [
      {
        path: '',
        component: NbLoginComponent, //LoginComponent, //NbLoginComponent,
      },
      {
        path: 'login',
        component: NbLoginComponent, //LoginComponent, //NbLoginComponent,
      },
      {
        path: 'register',
        component: NbRegisterComponent, //RegisterComponent,//NbRegisterComponent,
      },
      {
        path: 'logout',
        component: NbLogoutComponent,
      },
      {
        path: 'request-password',
        component: NbRequestPasswordComponent, //RequestPasswordComponent, //NbRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NbResetPasswordComponent, //ResetPasswordComponent, //NbResetPasswordComponent,
      },
    ],
  },
  // { path: '', redirectTo: '/pages/inventories', pathMatch: 'full' },
  { path: '**', redirectTo: '/auth/login' },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
