import {BusinessProfile} from './business-profile'

export interface IGeometryPoint {
    type: string;
    coordinates: number[];//longitude first and then latitude
}

export interface IGeoJsonPoint {
    type: string;
    geometry: IGeometryPoint;
    properties?: BusinessProperties;//Item;
    $key?: string;
}

export class BusinessProperties {
    businessProfile: BusinessProfile
    stripeAccountId: string
    stripeChargesEnabled: boolean
    stripeDetailsSubmitted: boolean
    onboardingComplete: boolean
    inventories: string[]
    open: boolean

    constructor() {
      this.businessProfile = new BusinessProfile()
      this.stripeAccountId = ''
      this.stripeChargesEnabled = false
      this.stripeDetailsSubmitted = false
      this.onboardingComplete = false
      this.inventories = ['']
      this.open = false
    }
}

export class GeoJsonPoint implements IGeoJsonPoint {
  docId?: string
  code:string
  type = 'Feature';
  geometry: IGeometryPoint;
  objectID: string;

  constructor(coordinates, code, public properties?) {//, public transactions?) {
    this.geometry = {
      type: 'Point',
      coordinates: coordinates
    }
    this.code = code
  }
}

export class FeatureCollection {
  type = 'FeatureCollection'
  constructor(public features: Array<GeoJsonPoint>) {}
}