import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
//import { AuthServiceService } from './auth-service.service';
import { NbAuthService } from '@nebular/auth';
import { tap, take, switchMap } from 'rxjs/operators'
import { AngularFireAuth } from '@angular/fire/auth';


@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate{

  constructor(public authService: NbAuthService, public router: Router, 
    public auth:AngularFireAuth) { }

  // canActivate(): boolean {
  //   if (!this.auth.isAuthenticated()) {
  //     this.router.navigate(['auth']);
  //     return false;
  //   }
  //   return true;
  // }

  // canActivate() {
  //   return this.authService.isAuthenticated()
  //   .pipe(
  //       tap(authenticated => {
  //       	console.log('authGuard, authenticated', authenticated)
  //         if (!authenticated) {
  //           this.router.navigate(['auth/login']);
  //         }
  //       }),
  //     ) // canActive can return Observable<boolean>, which is exactly what isAuthenticated returns
  // }

  canActivate() {
        return this.auth.authState.pipe(
            take(1),
            switchMap(async (authState) => {
                if (authState) { // check are user is logged in
                    const token = await authState.getIdTokenResult()
                    if (token.claims.profileComplete===undefined || token.claims.profileComplete===false) { // check claims
                        this.router.navigate(['/pages/onboarding'])
                        return false
                    } else {
                        return true
                    }
                } else {
                    this.router.navigate(['/auth/login'])
                    return false
                }
            }),
        )
    }
}
